import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Summon partners with Chartered Accountants and Law Institute of Victoria"
      description="Summon have partnered with Chartered Accountants ANZ and Law Institute of Victoria to offer exclusive concierge services for members "
      location={location}
    />

    <PageHeader
      title="Summon partners with Chartered Accountants and Law Institute of Victoria"
      text="Summon have partnered with Chartered Accountants ANZ and Law Institute of Victoria to offer exclusive concierge services for members "
    />

    <SectionPageMargin>

      <Heading2>Members get access to exclusive offer</Heading2>

      <Paragraph1>
        Summon have partnered with Chartered Accountants ANZ and Law Institute of Victoria to bring our prestige car selling concierge service to their members.
      </Paragraph1>

      <Paragraph1>
        Busy professionals wishing to sell their prestige car have limited options; selling the car to a dealer (or trading-in) means taking a hefty discount from the retail sale price, but selling the car privately is time-consuming and often a hassle.
      </Paragraph1>

      <Paragraph1>
        This makes the Summon service perfect for professionals such as accountants and lawyers, saving them time and money.
      </Paragraph1>

      <Paragraph1>
        For CA ANZ or LIV members wishing to sell a prestige car, please make an enquiry and quote code #LIV-25 or #CA-25
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
